import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Center, Heading } from '@chakra-ui/layout';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, IconButton } from '@chakra-ui/button';
import { useAuthContext } from '../../context/AuthContextProvider';
import { login } from './API/authAPI';
import { Alert, AlertIcon } from '@chakra-ui/alert';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

export default function Login() {
	const [showPassword, setShowPassword] = useState(false);

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Invalid Email').required('Email is required'),
		password: Yup.string().required('Password is required'),
	});

	const [, dispatch] = useAuthContext();

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus }) => {
			return login(values)
				.then((res) => {
					localStorage.setItem('auth', res.data.token);
					dispatch({ type: 'LOGIN_STATUS', value: true });
				})
				.catch((err) => {
					setStatus('An error occurred, please try again later');
				});
		},
	});

	return (
		<Center minHeight='100vh' bg='cyan.50'>
			<Box width='100%' maxWidth='450px' shadow='md' p='12' bg='white'>
				<Heading as='h5' size='lg' colorScheme='cyan' textAlign='center' mb='8'>
					Nesto Backend Services
				</Heading>
				{formik.status && (
					<Alert status='error' mb='4' fontSize='sm'>
						<AlertIcon />
						{formik.status}
					</Alert>
				)}
				<form onSubmit={formik.handleSubmit}>
					<FormControl
						isInvalid={formik.touched.email && formik.errors.email}
						mb='4'
					>
						<FormLabel>Email</FormLabel>
						<Input {...formik.getFieldProps('email')} />
						<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={formik.touched.password && formik.errors.password}
						mb='4'
					>
						<FormLabel>Password</FormLabel>
						<InputGroup>
							<Input
								{...formik.getFieldProps('password')}
								type={showPassword ? 'text' : 'password'}
							/>
							<InputRightElement>
								<IconButton
									icon={showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
									onClick={() => setShowPassword(!showPassword)}
									size='sm'
								/>
							</InputRightElement>
						</InputGroup>
						<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
					</FormControl>
					<Button
						type='submit'
						colorScheme='blue'
						variant='solid'
						w='full'
						isLoading={formik.isSubmitting}
					>
						Login
					</Button>
				</form>
			</Box>
		</Center>
	);
}
