import { getAccessToken } from '../utils/tokenFunctions';

export function setupAxios(axios, dispatch, toast) {
	axios.interceptors.request.use(
		(config) => {
			const accessToken = getAccessToken();
			if (accessToken && !config.headers.Authorization) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}

			return config;
		},
		(err) => Promise.reject(err)
	);
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (err) {
			if (err.response?.status === 401 || err.response?.status === 420) {
				localStorage.removeItem('auth');
				const id = 'unauthorized-toast';
				if (!toast.isActive(id)) {
					toast({
						id,
						description: err.response?.data?.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
				}
				dispatch({ type: 'LOGIN_STATUS', value: false });
			}
			return Promise.reject(err);
		}
	);
}
