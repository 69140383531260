import { Button } from '@chakra-ui/button';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import React, { useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

/**
 * @param  {boolean} isOpen - Whether to show the modal or not
 * @param  {*} onClose - Function to close the modal
 * @param  {string} url - Url to perform delete on selected item
 * @param  {Object} selectedItem - The item to be deleted
 * @param  {*} refetchData - Function to refetch data after deleting
 */
export default function DeleteConfirmModal({
	isOpen,
	onClose,
	url,
	selectedItem,
	refetchData,
}) {
	const toast = useToast();

	const [loading, setLoading] = useState(false);

	const deleteRequest = () => {
		setLoading(true);
		axios
			.delete(`${url}/${selectedItem.id}`)
			.then((res) => {
				toast({
					description: 'Item deleted successfully',
					status: 'success',
					isClosable: true,
					variant: 'left-accent',
					position: 'bottom-left',
				});
				setLoading(false);
				refetchData();
				onClose();
			})
			.catch((err) => {
				if (err.response?.status !== 401 && err.response?.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					setLoading(false);
					onClose();
				}
			});
	};

	const cancelRef = useRef();
	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader>Delete Item?</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					Are you sure you want to delete this item?
				</AlertDialogBody>
				<AlertDialogFooter>
					<Button ref={cancelRef} onClick={onClose}>
						Cancel
					</Button>
					<Button
						colorScheme='red'
						ml={3}
						onClick={deleteRequest}
						isLoading={loading}
					>
						Delete
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}

DeleteConfirmModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired,
	selectedItem: PropTypes.object,
	refetchData: PropTypes.func.isRequired,
};
