import { useDisclosure } from '@chakra-ui/hooks';
import { Badge } from '@chakra-ui/layout';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { BsEyeFill, BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { useHistory } from 'react-router';
import DataTable from '../../../components/DataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal';
import { ZMS_BASE_URL } from '../../../constants';
import { AddTimeslotModalForm } from './partials/AddTimeslotModalForm';
import { EditTimeslotModalForm } from './partials/EditTimeslotModalForm';

export default function TimeslotList() {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${ZMS_BASE_URL}/timeslot`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const history = useHistory();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Zone',
				accessor: 'zone.name',
			},
			{
				Header: 'Active From',
				accessor: 'active_from',
				isDateField: true,
				filter: {
					type: 'date',
				},
				CSVFormat: (val) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Active To',
				accessor: 'active_to',
				isDateField: true,
				filter: {
					type: 'date',
				},
				CSVFormat: (val) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Customer Group',
				accessor: 'customer_group',
				filter: {
					type: 'select',
					options: [
						{
							value: 'general',
							label: 'Nesto',
						},
						{ value: 'other', label: 'Other' },
					],
				},
				custom: (v) => {
					if (v === 'general') {
						return 'Nesto';
					}
					return 'Other';
				},
				CSVFormat: (v) => {
					if (v === 'general') {
						return 'Nesto';
					}
					return 'Other';
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				custom: (v) => {
					if (v === 1) {
						return <Badge colorScheme='green'>Enabled</Badge>;
					}
					return <Badge colorScheme='red'>Disabled</Badge>;
				},
				filter: {
					type: 'select',
					options: [
						{
							value: 1,
							label: 'Enabled',
						},
						{ value: 0, label: 'Disabled' },
					],
				},
				CSVFormat: (v) => {
					if (v === 1) {
						return 'Enabled';
					}
					return 'Disabled';
				},
			},
			{
				Header: 'Sort order',
				accessor: 'sort_order',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: BsPencilFill,
				color: 'green',
				onTrigger: (item) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: BsTrashFill,
				color: 'red',
				onTrigger: (item) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
			{
				name: 'details',
				tooltip: 'Details',
				icon: BsEyeFill,
				color: 'blue',
				onTrigger: (item) => {
					history.push(`/tms/timeslot-list/${item.id}`);
				},
			},
		],
		[openEditModal, openDeleteModal, history]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditTimeslotModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddTimeslotModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='Timeslot List'
				refetchFlag={refetchFlag}
				filter={true}
			/>
		</>
	);
}
