import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React from 'react';

export default function AbsoluteSpinner() {
	return (
		<Center
			position='absolute'
			height='100%'
			width='100%'
			backgroundColor='rgba(255,255,255,0.7)'
			zIndex='10'
		>
			<Spinner
				thickness='6px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='xl'
			/>
		</Center>
	);
}
