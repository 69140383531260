import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button, IconButton } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { HStack, Text } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import axios from 'axios';
import { AsyncSelect } from 'chakra-react-select';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { BsPlusLg, BsXLg } from 'react-icons/bs';
import * as Yup from 'yup';
import { ZMS_BASE_URL } from '../../../../constants';
import { ifStringToArray } from '../../../../utils/ifStringToArray';

export const EditAreaModalForm = ({ url, isOpen, onClose, selectedItem }) => {
	const initialValues = {
		name: selectedItem ? selectedItem.name : '',
		city_id: selectedItem && selectedItem.city ? selectedItem.city.id : '',
		zone_id: selectedItem && selectedItem.zone ? selectedItem.zone.id : '',
		coordinates: selectedItem
			? { points: ifStringToArray(selectedItem.coordinates.points) }
			: {
					points: [
						{
							long: '',
							lat: '',
						},
					],
			  },
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Area name is required'),
		city_id: Yup.number().required('City is required'),
		zone_id: Yup.string(),
		coordinates: Yup.object().shape({
			points: Yup.array().of(
				Yup.object().shape({
					lat: Yup.number().required('Latitude is required'),
					long: Yup.number().required('Longitude is required'),
				})
			),
		}),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.put(`${url}/${selectedItem.id}`, {
					...values,
				})
				.then((res) => {
					toast({
						description: 'Item updated successfully',
						status: 'success',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					resetAndCloseAddModal();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	const [cityKeyword, setCityKeyword] = useState('');
	const [selectedCity, setSelectedCity] = useState();
	const [zoneKeyword, setZoneKeyword] = useState('');
	const [selectedZone, setSelectedZone] = useState();

	const loadCityOptions = () => {
		return axios
			.get(`${ZMS_BASE_URL}/city`, {
				params: {
					pagination: { page: 1, limit: 30 },
					filter: { keyword: cityKeyword },
				},
			})
			.then((res) => res.data.data)
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					return [];
				}
			});
	};

	const loadZoneOptions = () => {
		return axios
			.get(`${ZMS_BASE_URL}/zone`, {
				params: {
					pagination: { page: 1, limit: 30 },
					filter: { keyword: zoneKeyword },
				},
			})
			.then((res) => res.data.data)
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					return [];
				}
			});
	};

	useEffect(() => {
		if (selectedItem) {
			setSelectedCity(selectedItem.city);
			setSelectedZone(selectedItem.zone);
		}
	}, [selectedItem]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<ModalContent overflow='auto'>
				<ModalHeader>Edit Area</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl
							isInvalid={formik.touched.name && formik.errors.name}
							mb='4'
						>
							<FormLabel>Name</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={formik.touched.city_id && formik.errors.city_id}
							mb='4'
						>
							<FormLabel>City</FormLabel>
							<AsyncSelect
								cacheOptions
								defaultOptions
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								loadOptions={loadCityOptions}
								onInputChange={(v) => setCityKeyword(v)}
								value={selectedCity}
								onChange={(v) => {
									setSelectedCity(v);
									formik.setFieldValue('city_id', v.id);
								}}
							/>
							<FormErrorMessage>{formik.errors.city_id}</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={formik.touched.zone_id && formik.errors.zone_id}
							mb='4'
						>
							<FormLabel>Zone</FormLabel>
							<AsyncSelect
								cacheOptions
								defaultOptions
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								loadOptions={loadZoneOptions}
								onInputChange={(v) => setZoneKeyword(v)}
								value={selectedZone}
								onChange={(v) => {
									setSelectedZone(v);
									formik.setFieldValue('zone_id', v.id);
								}}
							/>
							<FormErrorMessage>{formik.errors.zone_id}</FormErrorMessage>
						</FormControl>
						<FormikProvider value={formik}>
							<FieldArray
								name='coordinates.points'
								render={(arrayHelpers) => (
									<>
										<Text fontWeight='semibold' mb='4'>
											Coordinates
										</Text>
										{formik.values.coordinates.points &&
											formik.values.coordinates.points.length > 0 &&
											formik.values.coordinates.points.map((val, index) => {
												return (
													<HStack
														justifyContent='space-between'
														alignItems='start'
														spacing={2}
														key={index}
														mb='4'
													>
														<FormControl
															isInvalid={
																formik.errors.coordinates &&
																formik.errors.coordinates.points &&
																formik.errors.coordinates.points[index] &&
																formik.errors.coordinates.points[index].lat
															}
														>
															<FormLabel>Latitude</FormLabel>
															<Input
																{...formik.getFieldProps(
																	`coordinates.points.${index}.lat`
																)}
																type='number'
															/>
															<FormErrorMessage>
																{formik.errors.coordinates &&
																	formik.errors.coordinates.points &&
																	formik.errors.coordinates.points[index] &&
																	formik.errors.coordinates.points[index].lat}
															</FormErrorMessage>
														</FormControl>
														<FormControl
															isInvalid={
																formik.errors.coordinates &&
																formik.errors.coordinates.points &&
																formik.errors.coordinates.points[index] &&
																formik.errors.coordinates.points[index].lat
															}
														>
															<FormLabel>Longitude</FormLabel>
															<Input
																{...formik.getFieldProps(
																	`coordinates.points.${index}.long`
																)}
																type='number'
															/>
															<FormErrorMessage>
																{formik.errors.coordinates &&
																	formik.errors.coordinates.points &&
																	formik.errors.coordinates.points[index] &&
																	formik.errors.coordinates.points[index].lat}
															</FormErrorMessage>
														</FormControl>
														{
															<Tooltip label='Delete'>
																<IconButton
																	colorScheme='red'
																	icon={<BsXLg />}
																	onClick={() => arrayHelpers.remove(index)}
																	size='sm'
																	disabled={
																		formik.values.coordinates &&
																		formik.values.coordinates.points &&
																		ifStringToArray(
																			formik.values.coordinates.points
																		).length <= 1
																	}
																/>
															</Tooltip>
														}
													</HStack>
												);
											})}
										<Button
											rightIcon={<BsPlusLg />}
											size='sm'
											colorScheme='green'
											onClick={() =>
												arrayHelpers.push({
													lat: '',
													long: '',
												})
											}
										>
											Add More
										</Button>
									</>
								)}
							/>
						</FormikProvider>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
