import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Center, Heading } from '@chakra-ui/layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import React, { useMemo, useState } from 'react';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DataTable from '../../../components/DataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal';
import { ZMS_BASE_URL } from '../../../constants';
import { ifStringToArray } from '../../../utils/ifStringToArray';
import { AddAreaModalForm } from './partials/AddAreaModalForm';
import { EditAreaModalForm } from './partials/EditAreaModalForm';

export default function AreaList() {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${ZMS_BASE_URL}/area`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Area',
				accessor: 'name',
			},
			{
				Header: 'City',
				accessor: 'city.name',
			},
			{
				Header: 'Zone',
				accessor: 'zone.name',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: BsPencilFill,
				color: 'green',
				onTrigger: (item) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: BsTrashFill,
				color: 'red',
				onTrigger: (item) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const expandFunction = (row) => {
		return (
			<Center px='10' py='6' bg='cyan.200'>
				<Box>
					<Heading size='sm' mb='2'>
						Coordinates
					</Heading>
					<Table
						style={{
							tableLayout: 'fixed',
							width: '100%',
							maxWidth: '600px',
						}}
						bg='white'
						rounded='md'
					>
						<Thead>
							<Tr>
								<Th
									width='250px'
									textAlign='center'
									borderRight='1px solid'
									borderRightColor='gray.100'
								>
									Latitude
								</Th>
								<Th width='250px' textAlign='center'>
									Longitude
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{ifStringToArray(row.coordinates.points).map((val, index) => {
								return (
									<Tr key={'coord' + row.id + index.toString()}>
										<Td
											borderRight='1px solid'
											borderRightColor='gray.100'
											textAlign='center'
										>
											{val.lat}
										</Td>
										<Td textAlign='center'>{val.long}</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
			</Center>
		);
	};

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditAreaModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddAreaModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='Area List'
				expandableRows={true}
				expandFunction={expandFunction}
				refetchFlag={refetchFlag}
				width='6xl'
			/>
		</>
	);
}
