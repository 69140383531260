import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuthContext } from './context/AuthContextProvider';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/auth/Login';
import { OrderList } from './pages/order/OrderList';
import ReportList from './pages/order/report/ReportList';
import { SiteList } from './pages/site/SiteList';
import SlotList from './pages/timeslot/slot/SlotList';
import TimeslotList from './pages/timeslot/timeslot/TimeslotList';
import UserList from './pages/user/UserList';
import AreaList from './pages/zone/area/AreaList';
import CityList from './pages/zone/city/CityList';
import ZoneList from './pages/zone/zone/ZoneList';

export default function Routes() {
	const [{ isLoggedIn }] = useAuthContext();
	return (
		<>
			{isLoggedIn ? (
				<DashboardLayout>
					<Switch>
						<Route path='/tms/timeslot-list/:tid' component={SlotList} />
					</Switch>
					<Switch>
						<Route exact path='/ums/user-list' component={UserList} />
						<Route exact path='/zms/city-list' component={CityList} />
						<Route exact path='/zms/area-list' component={AreaList} />
						<Route exact path='/zms/zone-list' component={ZoneList} />
						<Route path='/tms/timeslot-list' component={TimeslotList} />
						<Route exact path='/sms/site-list' component={SiteList} />
						<Route exact path='/oms/order-list' component={OrderList} />
						<Route exact path='/oms/reports' component={ReportList} />
						<Redirect to='/ums/user-list' />
					</Switch>
				</DashboardLayout>
			) : (
				<Switch>
					<Route exact path='/login' component={Login} />
					<Redirect to='/login' />
				</Switch>
			)}
		</>
	);
}
