import React, { createContext, useContext, useReducer } from 'react';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
	const initialState = {
		isLoggedIn: false,
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case 'LOGIN_STATUS':
				return { ...state, isLoggedIn: action.value };
			default:
				throw new Error(`Unhandled action type: ${action.type}`);
		}
	};

	const [controller, dispatch] = useReducer(reducer, initialState);

	return (
		<AuthContext.Provider value={[controller, dispatch]}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	return useContext(AuthContext);
};
