import { useDisclosure } from '@chakra-ui/hooks';
import { Badge } from '@chakra-ui/layout';
import {
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/modal';
import React, { useMemo, useState } from 'react';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router';
import DataTable from '../../../components/DataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal';
import { ZMS_BASE_URL } from '../../../constants';
import { AddSlotModalForm } from './partials/AddSlotModalForm';
import { EditSlotModalForm } from './partials/EditSlotModalForm';

export default function SlotList() {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${ZMS_BASE_URL}/slot`, []);
	const { tid } = useParams();

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const history = useHistory();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Slot Type',
				accessor: 'slot_type',
				custom: (v) => {
					if (v === 'normal') {
						return <Badge colorScheme='gray'>Normal</Badge>;
					}
					return <Badge colorScheme='red'>Express</Badge>;
				},
				filter: {
					type: 'select',
					options: [
						{
							label: 'Normal',
							value: 'normal',
						},
						{
							label: 'Express',
							value: 'express',
						},
					],
				},
			},
			{
				Header: 'Start Time',
				accessor: 'start_time',
			},
			{
				Header: 'Cut off time',
				accessor: 'cutoff_time',
			},
			{
				Header: 'Delivery start',
				accessor: 'delivery_from_time',
			},
			{
				Header: 'Delivery end',
				accessor: 'delivery_to_time',
			},
			{
				Header: 'Pick pack allocation interval',
				accessor: 'pick_pack_allocation',
			},
			{
				Header: 'Hand off time',
				accessor: 'hand_off_time',
			},
			{
				Header: 'Dispatch time',
				accessor: 'dispatch_time',
			},
			{
				Header: 'Max orders',
				accessor: 'max_orders',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: BsPencilFill,
				color: 'green',
				onTrigger: (item) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: BsTrashFill,
				color: 'red',
				onTrigger: (item) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	const closeFunction = () => {
		history.push('/tms/timeslot-list');
	};

	return (
		<Modal
			size='6xl'
			isOpen={true}
			onClose={closeFunction}
			closeOnOverlayClick={true}
		>
			<ModalOverlay />
			<EditSlotModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddSlotModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
				t_id={tid}
			/>
			<ModalContent width='95%' pt='5'>
				<ModalCloseButton zIndex='10' />
				<DataTable
					columns={columns}
					actions={actions}
					url={`${url}/${tid}`}
					exportCSV={true}
					addNewFunction={openAddModal}
					pageHeader='Slot List'
					refetchFlag={refetchFlag}
					filter={true}
				/>
			</ModalContent>
		</Modal>
	);
}
