import { useDisclosure } from '@chakra-ui/hooks';
import React, { useMemo, useState } from 'react';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DataTable from '../../../components/DataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal';
import { ZMS_BASE_URL } from '../../../constants';
import { AddCityModalForm } from './partials/AddCityModalForm';
import { EditCityModalForm } from './partials/EditCityModalForm';

export default function CityList() {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${ZMS_BASE_URL}/city`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'City',
				accessor: 'name',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: BsPencilFill,
				color: 'green',
				onTrigger: (item) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: BsTrashFill,
				color: 'red',
				onTrigger: (item) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openDeleteModal, openEditModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditCityModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddCityModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='City List'
				refetchFlag={refetchFlag}
				width='3xl'
			/>
		</>
	);
}
