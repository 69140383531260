import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthContextProvider } from './context/AuthContextProvider';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme/theme';

ReactDOM.render(
	<React.StrictMode>
		<AuthContextProvider>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</AuthContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
