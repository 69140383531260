import { Button, IconButton } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Flex, Heading, HStack, Square, Wrap } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Collapse } from '@chakra-ui/transition';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
	BsArrowClockwise,
	BsCaretDownFill,
	BsCaretUpFill,
	BsChevronBarLeft,
	BsChevronBarRight,
	BsChevronLeft,
	BsChevronRight,
	BsDownload,
	BsFunnel,
	BsFunnelFill,
	BsPlusLg,
	BsSearch,
} from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Tooltip } from '@chakra-ui/tooltip';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';
import AbsoluteSpinner from './AbsoluteSpinner';
import axios from 'axios';
import { useAuthContext } from '../context/AuthContextProvider';
import { useToast } from '@chakra-ui/toast';
import resolvePath from 'object-resolve-path';
import { getAccessToken } from '../utils/tokenFunctions';

export default function DataTableWithHeaders({
	columns,
	url,
	filter,
	expandableRows,
	exportCSV,
	addNewFunction,
	width,
}) {
	const [, dispatch] = useAuthContext();
	const toast = useToast();

	const organizeFromColumns = () => {
		let initialFilterState = {
			search: '',
			order_by: 'id',
			order_direction: 'desc',
			page_number: 0,
			size: 10,
		};
		let initialCollapseState = {};
		columns.forEach((col, index) => {
			if (col.filter) {
				initialFilterState[col.accessor] = '';
			}
			if (col.expand) {
				initialCollapseState[col.accessor] = false;
			}
		});
		if (filter) {
			initialCollapseState['filter'] = true;
		}
		return { initialFilterState, initialCollapseState };
	};

	const { initialFilterState, initialCollapseState } = organizeFromColumns();

	const [loading, setLoading] = useState(true);
	const [filterState, setFilterState] = useState(initialFilterState);
	const [collapse, setCollapse] = useState(initialCollapseState);
	const [tableData, setTableData] = useState();
	const [refetch, setRefetch] = useState(false);
	const [total, setTotal] = useState(1);
	const [search, setSearch] = useState('');

	const updateCollapse = (accessor) => {
		return setCollapse({ ...collapse, [accessor]: !collapse[accessor] });
	};

	const handleSort = (accessor) => {
		if (filterState.order_by !== accessor) {
			setFilterState({
				...filterState,
				order_by: accessor,
				order_direction: 'asc',
			});
		}
		if (filterState.order_by === accessor) {
			setFilterState({
				...filterState,
				order_direction: filterState.order_direction === 'asc' ? 'desc' : 'asc',
			});
		}
	};

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			axios
				.post(url, null, {
					headers: {
						...filterState,
						Authorization: getAccessToken(),
					},
				})
				.then((res) => {
					setTableData(res.data.data);
					setTotal(res.data.total);
					setLoading(false);
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						if (err?.response?.data?.message) {
							toast({
								description: err.response.data.message || 'An error occurred',
								status: 'error',
								isClosable: true,
								variant: 'left-accent',
								position: 'bottom-left',
							});
						}
						setTableData([]);
						setLoading(false);
					}
				});
		};

		getData();
	}, [filterState, refetch, dispatch, toast, url]);

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilterState((state) => ({ ...state, search }));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [search]);

	const getValue = (row, col) => {
		try {
			if (
				resolvePath(row, col.accessor) === null ||
				resolvePath(row, col.accessor) === undefined
			) {
				return '-';
			}
			if (col.isDateField) {
				return format(parseISO(resolvePath(row, col.accessor)), 'dd/MM/yyyy');
			}
			if (col.custom) {
				return col.custom(resolvePath(row, col.accessor), row);
			}
			return resolvePath(row, col.accessor);
		} catch (err) {
			return resolvePath(row, col.accessor);
		}
	};

	return (
		<Box
			bg='white'
			shadow='sm'
			rounded='md'
			position='relative'
			maxWidth={width}
		>
			{loading && <AbsoluteSpinner />}
			<Flex
				py='6'
				px='8'
				justifyContent='space-between'
				alignItems='center'
				shadow='sm'
			>
				<Heading size='lg'>User Management</Heading>
				<HStack spacing={3}>
					<InputGroup size='sm'>
						<Input
							type='text'
							placeholder='Search'
							onChange={(e) => setSearch(e.target.value)}
							value={search}
						/>
						<InputRightElement children={<BsSearch />} />
					</InputGroup>
					{exportCSV ? (
						!_.isEmpty(tableData) ? (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton
										as={CSVLink}
										icon={<BsDownload />}
										data={tableData}
										size='sm'
									/>
								</Box>
							</Tooltip>
						) : (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton icon={<BsDownload />} size='sm' disabled />
								</Box>
							</Tooltip>
						)
					) : null}
					{addNewFunction && (
						<Tooltip label='Add New'>
							<IconButton icon={<BsPlusLg />} size='sm' />
						</Tooltip>
					)}
					<Tooltip label='Refresh Data'>
						<IconButton
							icon={<BsArrowClockwise />}
							size='sm'
							onClick={() => setRefetch(true)}
						/>
					</Tooltip>
					{filter && (
						<Tooltip label='Filters'>
							<IconButton
								icon={<BsFunnelFill />}
								onClick={() => updateCollapse('filter')}
								size='sm'
							/>
						</Tooltip>
					)}
				</HStack>
			</Flex>
			<Box overflow='auto' px='8' py='8'>
				{filter && (
					<Collapse in={collapse.filter}>
						<Box p='2' mb='10' rounded='md'>
							<Wrap spacing={5} justify='end'>
								{columns.map((col, index) => {
									if (col.filter) {
										if (col.filter.type === 'date') {
											return (
												<FormControl width='auto' key={`filter_${col.Header}`}>
													<FormLabel fontSize='sm' mb='0'>
														{col.Header}
													</FormLabel>
													<Input
														type='date'
														value={filterState[col.accessor]}
														onChange={(e) =>
															setFilterState({
																...filterState,
																[col.accessor]: e.target.value,
															})
														}
														size='sm'
													/>
												</FormControl>
											);
										}
										if (col.filter.type === 'select') {
											return (
												<FormControl width='auto' key={`filter_${col.Header}`}>
													<FormLabel>{col.Header}</FormLabel>
													<Select
														placeholder='Choose option'
														value={filterState[col.accessor]}
														onChange={(e) =>
															setFilterState({
																...filterState,
																[col.accessor]: e.target.value,
															})
														}
													>
														{col.filter.options.map((option) => {
															return (
																<option value={option.value}>
																	{option.label}
																</option>
															);
														})}
													</Select>
												</FormControl>
											);
										}
									}
									return null;
								})}
							</Wrap>
						</Box>
					</Collapse>
				)}
				<Table size='md' mb='16'>
					<Thead>
						<Tr>
							{expandableRows && <Th></Th>}
							{columns.map((col, index) => {
								return (
									<Th
										key={col.accessor}
										onClick={() => handleSort(col.accessor)}
										cursor='pointer'
										width={col.width ? col.width : 'auto'}
									>
										<Flex
											alignItems='center'
											spacing={1}
											justifyContent={
												index === 0
													? 'left'
													: index === columns.length - 1
													? 'right'
													: 'center'
											}
										>
											{col.Header}

											{filterState.order_by === col.accessor ? (
												filterState.order_direction === 'asc' ? (
													<BsCaretUpFill />
												) : (
													<BsCaretDownFill />
												)
											) : (
												<Box w='12px'></Box>
											)}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					</Thead>
					{tableData && tableData.length > 0 ? (
						<Tbody>
							{tableData.map((row, index) => {
								return (
									<Tr key={row.id}>
										{expandableRows && <Td></Td>}
										{columns.map((col, colIndex) => {
											return (
												<Td
													key={`${row.id}_${colIndex}`}
													textAlign={
														colIndex === 0
															? 'left'
															: colIndex === columns.length - 1
															? 'right'
															: 'center'
													}
												>
													{getValue(row, col)}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					) : (
						<Tbody>
							<Tr>
								<Td
									colSpan={expandableRows ? columns.length + 1 : columns.length}
									textAlign='center'
								>
									No data
								</Td>
							</Tr>
						</Tbody>
					)}
				</Table>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						leftIcon={<BsFunnel />}
						onClick={() => setFilterState({ ...initialFilterState })}
						colorScheme='orange'
					>
						Reset Filters
					</Button>

					<HStack spacing={3} alignItems='center' justifyContent='flex-end'>
						<Select
							width='auto'
							size='sm'
							value={filterState.size}
							onChange={(e) =>
								setFilterState({
									...filterState,
									size: parseInt(e.target.value),
								})
							}
						>
							<option value='10'>10 Rows</option>
							<option value='20'>20 Rows</option>
							<option value='50'>50 Rows</option>
						</Select>
						<IconButton
							icon={<BsChevronBarLeft />}
							onClick={() => setFilterState({ ...filterState, page_number: 0 })}
							disabled={filterState.page_number === 0}
							size='sm'
						/>
						<IconButton
							icon={<BsChevronLeft />}
							onClick={() =>
								setFilterState({
									...filterState,
									page_number: filterState.page_number - 1,
								})
							}
							disabled={filterState.page_number === 0}
							size='sm'
						/>
						{/* {page > 0 && (
						<Button size='sm' onClick={() => setPage(page - 1)} variant='ghost'>
							{page}
						</Button>
					)} */}
						<Square size='30px' color='white' bg='blue.500' rounded='md'>
							{filterState.page_number + 1}
						</Square>
						{/* {page < 400 / 10 - 1 && (
						<Button onClick={() => setPage(page + 1)} variant='ghost'>
							{page + 2}
						</Button>
					)} */}
						<IconButton
							icon={<BsChevronRight />}
							onClick={() =>
								setFilterState({
									...filterState,
									page_number: filterState.page_number + 1,
								})
							}
							disabled={
								filterState.page_number >=
								Math.ceil(total / filterState.size) - 1
							}
							size='sm'
						/>
						<IconButton
							icon={<BsChevronBarRight />}
							onClick={() => setFilterState(total / filterState.size - 1)}
							disabled={
								filterState.page_number ===
								Math.ceil(total / filterState.size) - 1
							}
							size='sm'
						/>
					</HStack>
				</Flex>
			</Box>
		</Box>
	);
}

DataTableWithHeaders.prototypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			Header: PropTypes.string.isRequired,
			accessor: PropTypes.string.isRequired,
			isDateField: PropTypes.bool,
			filter: PropTypes.shape({
				type: PropTypes.oneOf(['date', 'select']),
				options: PropTypes.arrayOf(
					PropTypes.shape({
						label: PropTypes.string,
						value: PropTypes.string,
					})
				),
			}),
		})
	),
	url: PropTypes.string.isRequired,
	filter: PropTypes.bool,
	expandableRows: PropTypes.bool,
	exportCSV: PropTypes.bool,
	addNewFunction: PropTypes.func,
};

DataTableWithHeaders.defaultProps = {
	filter: false,
	expandableRows: false,
	exportCSV: false,
	addNewFunction: null,
};
