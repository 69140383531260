import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Grid } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export const AddSlotModalForm = ({
	url,
	isOpen,
	onClose,
	refetchData,
	t_id,
}) => {
	const initialValues = {
		t_id,
		slot_type: '',
		start_time: '',
		cutoff_time: '',
		delivery_from_time: '',
		delivery_to_time: '',
		hand_off_time: '',
		dispatch_time: '',
		pick_pack_allocation: '',
		max_orders: '',
	};

	const validationSchema = Yup.object().shape({
		slot_type: Yup.string().required('Slot type is required'),
		start_time: Yup.string().required('Start Time is required'),
		cutoff_time: Yup.string().required('Cut Off Time is required'),
		delivery_from_time: Yup.string().required('Delivery From is required'),
		delivery_to_time: Yup.string().required('Delivery To is required'),
		hand_off_time: Yup.string().required('Hand Off Time is required'),
		dispatch_time: Yup.string().required('Dispatch Time is required'),
		pick_pack_allocation: Yup.number().required(
			'Pick Pack Allocation is required'
		),
		max_orders: Yup.number().required('Max Orders is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.post(`${url}`, { ...values })
				.then((res) => {
					toast({
						description: 'Item added successfully',
						status: 'success',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Slot</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<Grid templateColumns='repeat(2, 1fr)' gap={4}>
							<FormControl
								isInvalid={formik.touched.slot_type && formik.errors.slot_type}
								mb='4'
							>
								<FormLabel>Slot Type</FormLabel>
								<Select
									placeholder='Select an item'
									{...formik.getFieldProps('slot_type')}
								>
									<option value='normal'>Normal</option>
									<option value='express'>Express</option>
								</Select>
								<FormErrorMessage>{formik.errors.slot_type}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.start_time && formik.errors.start_time
								}
								mb='4'
							>
								<FormLabel>Start Time</FormLabel>
								<Input {...formik.getFieldProps('start_time')} type='time' />
								<FormErrorMessage>{formik.errors.start_time}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.cutoff_time && formik.errors.cutoff_time
								}
								mb='4'
							>
								<FormLabel>Cut Off Time</FormLabel>
								<Input {...formik.getFieldProps('cutoff_time')} type='time' />
								<FormErrorMessage>{formik.errors.cutoff_time}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.delivery_from_time &&
									formik.errors.delivery_from_time
								}
								mb='4'
							>
								<FormLabel>Delivery From</FormLabel>
								<Input
									{...formik.getFieldProps('delivery_from_time')}
									type='time'
								/>
								<FormErrorMessage>
									{formik.errors.delivery_from_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.delivery_to_time &&
									formik.errors.delivery_to_time
								}
								mb='4'
							>
								<FormLabel>Delivery To</FormLabel>
								<Input
									{...formik.getFieldProps('delivery_to_time')}
									type='time'
								/>
								<FormErrorMessage>
									{formik.errors.delivery_to_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.hand_off_time && formik.errors.hand_off_time
								}
								mb='4'
							>
								<FormLabel>Hand Off Time</FormLabel>
								<Input {...formik.getFieldProps('hand_off_time')} type='time' />
								<FormErrorMessage>
									{formik.errors.hand_off_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.dispatch_time && formik.errors.dispatch_time
								}
								mb='4'
							>
								<FormLabel>Dispatch Time</FormLabel>
								<Input {...formik.getFieldProps('dispatch_time')} type='time' />
								<FormErrorMessage>
									{formik.errors.dispatch_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.pick_pack_allocation &&
									formik.errors.pick_pack_allocation
								}
								mb='4'
							>
								<FormLabel>Picker Packer Allocation Interval</FormLabel>
								<Input
									{...formik.getFieldProps('pick_pack_allocation')}
									type='number`'
								/>
								<FormErrorMessage>
									{formik.errors.pick_pack_allocation}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.max_orders && formik.errors.max_orders
								}
								mb='4'
							>
								<FormLabel>Max Orders</FormLabel>
								<Input {...formik.getFieldProps('max_orders')} type='number' />
								<FormErrorMessage>{formik.errors.max_orders}</FormErrorMessage>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
