import { IconButton } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import DataTable from '../../components/DataTable';
import { ZMS_BASE_URL } from '../../constants';

export const SiteList = () => {
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${ZMS_BASE_URL}/sites`, []);

	const toast = useToast();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'SAP Site ID',
				accessor: 'sap_website_id',
			},
			{
				Header: 'Site name',
				accessor: 'website_name',
			},
		],
		[]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	const syncData = () => {
		axios
			.post(`${url}/sync`, null)
			.then((res) => {
				refetchData();
				toast({
					description: 'Details sync successful',
					status: 'success',
					isClosable: true,
					variant: 'left-accent',
					position: 'bottom-left',
				});
			})
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
				}
			});
	};

	const customButtonFunction = () => {
		return (
			<Tooltip label='Synchronize data with Magento'>
				<IconButton
					px='4'
					onClick={() => syncData()}
					icon={<AiOutlineSync />}
					size='sm'
					colorScheme='teal'
				/>
			</Tooltip>
		);
	};

	return (
		<DataTable
			columns={columns}
			url={url}
			pageHeader='Store List'
			refetchFlag={refetchFlag}
			customButtonFunction={customButtonFunction}
			refreshData={true}
			exportCSV={true}
			isSearchable={false}
			isPagination={false}
			width='3xl'
		/>
	);
};
