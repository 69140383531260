import { Button, IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import Icon from '@chakra-ui/icon';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import React from 'react';
import { BsBoxArrowRight, BsList } from 'react-icons/bs';
import { useAuthContext } from '../context/AuthContextProvider';
import Sidenav from './partials/Sidenav';

export default function DashboardLayout({ children }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [, dispatch] = useAuthContext();

	const logout = () => {
		localStorage.removeItem('auth');
		dispatch({ type: 'LOGIN_STATUS', value: false });
	};

	return (
		<Box bg='gray.100'>
			<Sidenav isOpen={isOpen} onClose={onClose} />
			<Flex
				minHeight='100vh'
				direction='column'
				marginLeft={{ base: '0', lg: '280px' }}
			>
				<Flex
					alignItems='center'
					justifyContent='space-between'
					px={{ base: 2, sm: 16 }}
					py='6'
					as='header'
				>
					<Flex alignItems='center'>
						<IconButton
							icon={<Icon as={BsList} w='24px' h='24px' />}
							variant='ghost'
							display={{ base: 'inline-block', md: 'none' }}
							onClick={() => onOpen()}
							mr='2'
						/>
						<Heading fontSize='xl'>Welcome</Heading>
					</Flex>
					<Button
						leftIcon={<Icon as={BsBoxArrowRight} />}
						colorScheme='yellow'
						onClick={() => logout()}
					>
						Logout
					</Button>
				</Flex>
				<Box p={{ base: 2, sm: 16 }} flex='1'>
					{children}
				</Box>
				<Box p={8} as='footer' textAlign='center'>
					<Text fontSize='md'>© 2021, made by Nesto for a better web.</Text>
				</Box>
			</Flex>
		</Box>
	);
}
