import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { AsyncSelect } from 'chakra-react-select';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { ZMS_BASE_URL } from '../../../../constants';

export const AddZoneModalForm = ({ url, isOpen, onClose, refetchData }) => {
	const initialValues = {
		name: '',
		site_id: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('City is required'),
		site_id: Yup.string().required('Site is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.post(`${url}`, { ...values })
				.then((res) => {
					toast({
						description: 'Item added successfully',
						status: 'success',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const [siteKeyword, setSiteKeyword] = useState('');
	const [selectedSite, setSelectedSite] = useState(null);

	const loadSiteOptions = () => {
		return axios
			.get(`${ZMS_BASE_URL}/sites`, {
				params: {
					pagination: { page: 1, limit: 30 },
					filter: { keyword: siteKeyword },
				},
			})
			.then((res) => res.data.data)
			.catch((err) => []);
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Zone</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl
							isInvalid={formik.touched.name && formik.errors.name}
							mb='4'
						>
							<FormLabel>Zone</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={formik.touched.site_id && formik.errors.site_id}
							mb='4'
						>
							<FormLabel>Site</FormLabel>
							<AsyncSelect
								cacheOptions
								defaultOptions
								getOptionLabel={(option) => option.website_name}
								getOptionValue={(option) => option.id}
								loadOptions={loadSiteOptions}
								onInputChange={(v) => setSiteKeyword(v)}
								value={selectedSite}
								onChange={(v) => {
									setSelectedSite(v);
									formik.setFieldValue('site_id', v.id + '');
								}}
							/>
							<FormErrorMessage>{formik.errors.site_id}</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
