import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import DataTableWithHeaders from '../../components/DataTableWithHeaders';
import { UMS_BASE_URL } from '../../constants';

export default function UserList() {
	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Mobile',
				accessor: 'mobile',
				width: '130px',
			},
			{
				Header: 'Created At',
				accessor: 'createdAt',
				isDateField: true,
				CSVFormat: (val) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Updated At',
				accessor: 'updatedAt',
				isDateField: true,
				isPrintable: false,
			},
		],
		[]
	);

	return (
		<DataTableWithHeaders
			columns={columns}
			url={`${UMS_BASE_URL}/list_admin_users`}
			exportCSV={true}
			width='6xl'
		/>
	);
}
