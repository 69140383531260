import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAuthContext } from './context/AuthContextProvider';
import { setupAxios } from './lib/setupAxios';
import Routes from './Routes';

function App() {
	const [loading, setLoading] = useState(true);
	const [, dispatch] = useAuthContext();

	const toast = useToast();

	setupAxios(axios, dispatch, toast);

	useEffect(() => {
		const token = localStorage.getItem('auth');
		if (!token) {
			dispatch({ type: 'LOGIN_STATUS', value: false });
			return setLoading(false);
		} else {
			dispatch({ type: 'LOGIN_STATUS', value: true });
			return setLoading(false);
		}
	}, [dispatch]);

	return <BrowserRouter>{!loading && <Routes />}</BrowserRouter>;
}

export default App;
