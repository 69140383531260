import { Button, IconButton } from '@chakra-ui/button';
import {
	FormControl,
	FormHelperText,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Flex, Heading, HStack, Square, Wrap } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Select } from '@chakra-ui/select';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import { Collapse } from '@chakra-ui/transition';
import axios from 'axios';
import { AsyncSelect } from 'chakra-react-select';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import resolvePath from 'object-resolve-path';
import PropTypes from 'prop-types';
import React, {
	Fragment,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { CSVLink } from 'react-csv';
import {
	BsArrowClockwise,
	BsCaretDownFill,
	BsCaretUpFill,
	BsChevronBarLeft,
	BsChevronBarRight,
	BsChevronDown,
	BsChevronLeft,
	BsChevronRight,
	BsDownload,
	BsFunnel,
	BsFunnelFill,
	BsPlusLg,
	BsSearch,
	BsThreeDotsVertical,
} from 'react-icons/bs';
import { useAuthContext } from '../context/AuthContextProvider';
import AbsoluteSpinner from './AbsoluteSpinner';

/**
 * Function that returns the React Child that should show in collapsible row
 * @name expandFunction
 * @function
 * @param {Object} data - Array of objects returned after getting data
 * @return {ReactChild} The react element to show in expanded table row
 */

/**
 * Function that launches modal to add new entry
 * @name addNewFunction
 * @function
 */

/**
 * Function that triggers on click of status icon button
 * @name onTrigger
 * @param {Object} selectedItem - Item on whose row the status button was clicked
 * @function
 */

/**
 * Function to add a custom button
 * @name customButtonFunction
 * @function
 * @return {ReactChild}
 */

/**
 * Utility for data-tables
 * @param  {Object[]} columns - Details about the columns
 * @param  {string} columns.Header - Header of table column
 * @param  {string} columns.accessor - String to access from data object
 * @param  {boolean} [columns.isDateField=false] - If column is a datefield, so it can be converted to dd/mm/yyyy
 * @param  {Object=} columns.filter - If the column is filterable
 * @param  {('date'|'select')} columns.filter.type - The type of filter input field, date or select
 * @param  {options} columns.filter.options - Object for select list
 * @param  {string} columns.filter.options.label - Label of select field
 * @param  {string|number} columns.filter.options.value - Value of select field
 * @param  {string|number} columns.filter.labelAccessor - Label key for async select
 * @param  {string|number} columns.filter.valueAccessor - Label value for async select
 * @param  {string|number} columns.filter.loadOptions - Function to load data for async select
 * @param  {boolean} columns.isRequired - If field is required to display data
 * @param  {boolean} columns.depends - If field depends on another filter to be fetched
 * @param  {boolean} columns.isPrintable - If false column does display when downloading as CSV
 * @param  {boolean} columns.display - If false column does display in data-table
 * @param  {boolean} [filterIsOpen=false] - If filter should be open initially
 * @param  {string} url - url of the get data function
 * @param  {boolean} [filter=false] - Whether to display the filter button
 * @param  {boolean} [expandableRows=false] - Whether rows can be collapsible
 * @param  {expandFunction} expandFunction
 * @param  {boolean} [exportCSV=false] - Display the export as CSV button
 * @param  {addNewFunction} [addNewFunction=null]
 * @param  {Object[]} [actions=null] - Object with icon action buttons
 * @param  {string} actions.name - Name of action (used as key for mapping)
 * @param  {string} actions.tooltip - Hover tooltip of button
 * @param  {ReactChild} actions.icon - Icon from react-icons
 * @param  {onTrigger} actions.onTrigger
 * @param  {string} pageHeader - Header of the DataTable card
 * @param  {boolean} [refreshData=true] - Whether to display refetch data button
 * @param  {customButtonFunction} [customButtonFunction=null]
 * @param  {boolean} [isSearchable=true] - Whether to show search button
 * @param  {boolean} [isPagination=true] - Whether to show pagination below table
 * @param  {string} width - Width in pixels or chakra sizes
 */
export default function DataTable({
	columns,
	url,
	filter,
	filterIsOpen,
	expandableRows,
	expandFunction,
	exportCSV,
	addNewFunction,
	actions,
	pageHeader,
	refreshData,
	customButtonFunction,
	isSearchable,
	isPagination,
	width,
	refetchFlag,
}) {
	const [, dispatch] = useAuthContext();
	const toast = useToast();

	const organizeFromColumns = () => {
		let initialFilterState = {
			keyword: '',
		};
		let initialAsyncSelectSearch = {};
		let initialAsyncSelectSelected = {};
		columns.forEach((col, index) => {
			if (col.filter) {
				initialFilterState[col.accessor] = col.filter.default || '';
				if (col.filter.type === 'async-select') {
					initialAsyncSelectSearch[col.accessor] = '';
					initialAsyncSelectSelected[col.accessor] = '';
				}
			}
		});
		return {
			initialFilterState,
			initialAsyncSelectSearch,
			initialAsyncSelectSelected,
		};
	};

	const {
		initialFilterState,
		initialAsyncSelectSearch,
		initialAsyncSelectSelected,
	} = organizeFromColumns();

	const [loading, setLoading] = useState(false);
	const [sortState, setSortState] = useState({
		fieldName: 'id',
		order: 'ASC',
	});
	const [filterState, setFilterState] = useState(initialFilterState);
	const [asyncSelectSearch, setAsyncSelectSearch] = useState(
		initialAsyncSelectSearch
	);
	const [asyncSelectSelected, setAsyncSelectSelected] = useState(
		initialAsyncSelectSelected
	);
	const [filterCollapse, setFilterCollapse] = useState(filterIsOpen);
	const [tableData, setTableData] = useState();
	const [CSVData, setCSVData] = useState(null);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(1);
	const [refetch, setRefetch] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [expandIndex, setExpandIndex] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState('No Data');

	const resetAllFilters = () => {
		setFilterState(initialFilterState);
		setAsyncSelectSearch(initialAsyncSelectSearch);
		setAsyncSelectSelected(initialAsyncSelectSelected);
		setKeyword('');
	};

	const updateExpand = (index) => {
		if (expandIndex.includes(index)) {
			let temp = expandIndex.filter((idx) => {
				return idx !== index;
			});
			return setExpandIndex(temp);
		}
		return setExpandIndex([...expandIndex, index]);
	};

	const expandAll = () => {
		let temp = Array.from(Array(limit).keys());
		setExpandIndex(temp);
	};

	const collapseAll = () => {
		setExpandIndex([]);
	};

	const handleSort = (accessor) => {
		if (sortState.fieldName === accessor) {
			return setSortState({
				...sortState,
				order: sortState.order === 'ASC' ? 'DESC' : 'ASC',
			});
		}
		return setSortState({ fieldName: accessor, order: 'ASC' });
	};

	const getValue = (row, col) => {
		try {
			if (
				resolvePath(row, col.accessor) === null ||
				resolvePath(row, col.accessor) === undefined
			) {
				return '-';
			}
			if (col.isDateField) {
				return format(parseISO(resolvePath(row, col.accessor)), 'dd/MM/yyyy');
			}
			if (col.custom) {
				return col.custom(resolvePath(row, col.accessor), row);
			}
			return resolvePath(row, col.accessor);
		} catch (err) {
			return resolvePath(row, col.accessor);
		}
	};

	useEffect(() => {
		const makeCSVDownloadData = (data) => {
			let dataArray = [];
			data.forEach((val) => {
				let temp = {};
				columns.forEach((col) => {
					if (col.isPrintable !== false) {
						try {
							temp[col.Header] = col.CSVFormat
								? col.CSVFormat(resolvePath(val, col.accessor))
								: resolvePath(val, col.accessor);
						} catch (err) {
							temp[col.Header] = resolvePath(val, col.accessor);
						}
					}
				});
				dataArray.push(temp);
			});
			return dataArray;
		};

		let hasRequiredData = true;
		columns.forEach((col) => {
			if (col.isRequired && !filterState[col.accessor]) {
				hasRequiredData = false;
			}
		});

		const getData = () => {
			setLoading(true);
			axios
				.get(url, {
					params: {
						pagination: { page, limit },
						filter: filterState,
						sort: { [sortState.fieldName]: sortState.order },
					},
				})
				.then((res) => {
					if (res.data?.data) {
						setTableData(res.data.data);
						setCSVData(makeCSVDownloadData(res.data.data));
						setTotal(res.data.total);
						return setLoading(false);
					}
					setTableData([]);
					setCSVData([]);
					return setLoading(false);
				})
				.catch((err) => {
					if (err.response?.status !== 401 && err.response?.status !== 420) {
						toast({
							description: err.response.data.message || 'An error occurred',
							status: 'error',
							isClosable: true,
							variant: 'left-accent',
							position: 'bottom-left',
						});
						setLoading(false);
					}
				});
		};
		if (hasRequiredData) {
			getData();
		} else {
			setTableData([]);
		}
	}, [
		limit,
		page,
		filterState,
		sortState,
		url,
		refetch,
		dispatch,
		toast,
		columns,
		refetchFlag,
	]);

	useEffect(() => {
		let hasRequiredData = true;
		columns.forEach((col) => {
			if (col.isRequired && !filterState[col.accessor]) {
				hasRequiredData = false;
			}
		});
		if (hasRequiredData) {
			setEmptyMessage('No Data');
		} else {
			setEmptyMessage('Please check required filters');
		}
	}, [columns, filterState]);

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilterState((state) => ({ ...state, keyword }));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keyword]);

	return (
		<Box
			bg='white'
			shadow='sm'
			rounded='md'
			position='relative'
			maxWidth={width}
		>
			{loading && <AbsoluteSpinner />}
			<Flex
				py='6'
				px='8'
				justifyContent='space-between'
				alignItems={{ base: 'start', md: 'center' }}
				shadow='sm'
				direction={{ base: 'column', md: 'row' }}
			>
				<Heading size='lg' mb={{ base: '4', md: '0' }}>
					{pageHeader}
				</Heading>
				<HStack spacing={3}>
					{isSearchable && (
						<InputGroup size='sm'>
							<Input
								type='text'
								placeholder='Search'
								onChange={(e) => setKeyword(e.target.value)}
								value={keyword}
							/>
							<InputRightElement children={<BsSearch />} />
						</InputGroup>
					)}
					{exportCSV ? (
						!_.isEmpty(CSVData) ? (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton
										as={CSVLink}
										icon={<BsDownload />}
										data={CSVData}
										size='sm'
									/>
								</Box>
							</Tooltip>
						) : (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton icon={<BsDownload />} size='sm' disabled />
								</Box>
							</Tooltip>
						)
					) : null}
					{addNewFunction && (
						<Tooltip label='Add New'>
							<IconButton
								icon={<BsPlusLg />}
								size='sm'
								onClick={addNewFunction}
							/>
						</Tooltip>
					)}
					{refreshData && (
						<Tooltip label='Refresh Data'>
							<IconButton
								icon={<BsArrowClockwise />}
								size='sm'
								onClick={() => setRefetch(true)}
							/>
						</Tooltip>
					)}
					{filter && (
						<Tooltip label='Filters'>
							<IconButton
								icon={<BsFunnelFill />}
								onClick={() => setFilterCollapse(!filterCollapse)}
								size='sm'
								colorScheme={filterCollapse ? 'orange' : 'gray'}
							/>
						</Tooltip>
					)}
					{customButtonFunction && customButtonFunction()}
				</HStack>
			</Flex>
			<Box overflow='auto' px='8' py='8'>
				<Collapse in={filterCollapse}>
					<Box p='2' mb='10' rounded='md'>
						<Wrap spacing={5} justify='end'>
							{columns.map((col, index) => {
								if (col.filter) {
									if (col.filter.type === 'date') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
											>
												<FormLabel fontSize='sm' mb='0'>
													{col.Header}
												</FormLabel>
												<Input
													type='date'
													value={filterState[col.accessor]}
													onChange={(e) =>
														setFilterState({
															...filterState,
															[col.accessor]: e.target.value,
														})
													}
													size='sm'
												/>
											</FormControl>
										);
									}
									if (col.filter.type === 'select') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
											>
												<FormLabel fontSize='sm' mb='0'>
													{col.Header}
												</FormLabel>
												<Select
													placeholder={
														col.filter.default ? null : 'Choose an option'
													}
													value={filterState[col.accessor]}
													onChange={(e) =>
														setFilterState({
															...filterState,
															[col.accessor]: e.target.value,
														})
													}
													size='sm'
												>
													{col.filter.options.map((option) => {
														return (
															<option value={option.value} key={option.value}>
																{option.label}
															</option>
														);
													})}
												</Select>
											</FormControl>
										);
									}
									if (col.filter.type === 'async-select') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
												minW='200px'
												isDisabled={
													col.depends && filterState[col.depends] === ''
														? true
														: false
												}
											>
												<FormLabel fontSize='sm' mb='0'>
													Zone
												</FormLabel>
												<AsyncSelect
													cacheOptions
													defaultOptions
													getOptionLabel={(option) =>
														option[col.filter.labelAccessor]
													}
													getOptionValue={(option) =>
														option[col.filter.valueAccessor]
													}
													loadOptions={() =>
														col.filter.loadOptions(
															asyncSelectSearch[col.accessor],
															filterState
														)
													}
													onInputChange={(v) =>
														setAsyncSelectSearch({
															...asyncSelectSearch,
															[col.accessor]: v,
														})
													}
													value={asyncSelectSelected[col.accessor]}
													onChange={(v) => {
														setFilterState({
															...filterState,
															[col.accessor]: v[col.filter.valueAccessor],
														});
														setAsyncSelectSelected({
															...asyncSelectSelected,
															[col.accessor]: v,
														});
													}}
													size='sm'
												/>
												{col.depends && filterState[col.depends] === '' && (
													<FormHelperText>
														{col.depends} is required
													</FormHelperText>
												)}
											</FormControl>
										);
									}
								}
								return null;
							})}
						</Wrap>
					</Box>
				</Collapse>
				<Table size='md' mb='16'>
					<Thead>
						<Tr>
							{expandableRows && (
								<Th width='50px' p='0'>
									<Menu>
										<Tooltip label='Expand options'>
											<MenuButton
												as={IconButton}
												icon={<BsThreeDotsVertical />}
												variant='ghost'
											/>
										</Tooltip>
										<MenuList>
											<MenuItem onClick={() => expandAll()}>
												+ Expand All
											</MenuItem>
											<MenuItem onClick={() => collapseAll()}>
												- Collapse All
											</MenuItem>
										</MenuList>
									</Menu>
								</Th>
							)}
							{columns.map((col, index) => {
								if (col.display === false) {
									return null;
								}
								return (
									<Th
										key={col.accessor}
										onClick={() => handleSort(col.accessor)}
										cursor='pointer'
										width={col.width ? col.width : 'auto'}
									>
										<Flex
											alignItems='center'
											spacing={1}
											justifyContent={
												index === 0
													? 'left'
													: index === columns.length - 1 && !actions
													? 'right'
													: 'center'
											}
											textAlign='center'
										>
											{col.Header}
											{sortState.fieldName === col.accessor ? (
												sortState.order === 'ASC' ? (
													<BsCaretUpFill />
												) : (
													<BsCaretDownFill />
												)
											) : (
												<Box w='12px'></Box>
											)}
										</Flex>
									</Th>
								);
							})}
							{actions && (
								<Th textAlign='right' width={`${actions.length * 55}px`}>
									Actions
								</Th>
							)}
						</Tr>
					</Thead>
					<Tbody>
						{tableData && tableData.length > 0 ? (
							tableData.map((row, rowIndex) => {
								return (
									<Fragment key={row.id}>
										<Tr>
											{expandableRows && (
												<Td
													onClick={() => updateExpand(rowIndex)}
													cursor='pointer'
												>
													{expandIndex.includes(rowIndex) ? (
														<BsChevronDown />
													) : (
														<BsChevronRight />
													)}
												</Td>
											)}
											{columns.map((col, colIndex) => {
												if (col.display === false) {
													return null;
												}
												return (
													<Td
														key={`${row.id}_${colIndex}`}
														textAlign={
															colIndex === 0
																? 'left'
																: colIndex === columns.length - 1 && !actions
																? 'right'
																: 'center'
														}
													>
														{getValue(row, col)}
													</Td>
												);
											})}
											{actions && (
												<Td>
													<Wrap spacing={2} display='flex' justifyContent='end'>
														{actions.map((action) => {
															return (
																<Tooltip
																	key={action.name + row.id}
																	label={action.tooltip}
																>
																	<IconButton
																		icon={<action.icon />}
																		colorScheme={action.color}
																		onClick={() => action.onTrigger(row)}
																		size='sm'
																	/>
																</Tooltip>
															);
														})}
													</Wrap>
												</Td>
											)}
										</Tr>
										{expandableRows && (
											<Tr p='0'>
												<Td
													colSpan={
														columns.length +
														(expandableRows ? 1 : 0) +
														(actions ? 1 : 0)
													}
													p='0'
													border={
														expandIndex.includes(rowIndex) ? 'auto' : 'none'
													}
												>
													<Collapse in={expandIndex.includes(rowIndex)}>
														{expandFunction(row)}
													</Collapse>
												</Td>
											</Tr>
										)}
									</Fragment>
								);
							})
						) : (
							<Tr>
								<Td
									colSpan={
										columns.length +
										(expandableRows ? 1 : 0) +
										(actions ? 1 : 0)
									}
									textAlign='center'
								>
									{emptyMessage}
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
				{isPagination | filter ? (
					<Flex justifyContent='space-between' alignItems='center'>
						{filter ? (
							<Button
								leftIcon={<BsFunnel />}
								onClick={resetAllFilters}
								colorScheme='orange'
							>
								Reset Filters
							</Button>
						) : (
							<Box></Box>
						)}

						{isPagination && (
							<HStack spacing={3} alignItems='center' justifyContent='flex-end'>
								<Select
									width='auto'
									size='sm'
									value={limit}
									onChange={(e) => {
										setLimit(parseInt(e.target.value));
										setPage(1);
									}}
								>
									<option value='10'>10 Rows</option>
									<option value='20'>20 Rows</option>
									<option value='50'>50 Rows</option>
								</Select>
								<IconButton
									icon={<BsChevronBarLeft />}
									onClick={() => setPage(1)}
									disabled={page === 1}
									size='sm'
								/>
								<IconButton
									icon={<BsChevronLeft />}
									onClick={() => setPage(page - 1)}
									disabled={page === 1}
									size='sm'
								/>
								{/* {page > 0 && (
						<Button size='sm' onClick={() => setPage(page - 1)} variant='ghost'>
							{page}
						</Button>
					)} */}
								<Square size='30px' color='white' bg='blue.500' rounded='md'>
									{page}
								</Square>
								{/* {page < 400 / 10 - 1 && (
						<Button onClick={() => setPage(page + 1)} variant='ghost'>
							{page + 2}
						</Button>
					)} */}
								<IconButton
									icon={<BsChevronRight />}
									onClick={() => setPage(page + 1)}
									disabled={page >= Math.ceil(total / limit)}
									size='sm'
								/>
								<IconButton
									icon={<BsChevronBarRight />}
									onClick={() => setPage(Math.ceil(total / limit))}
									disabled={page === Math.ceil(total / limit)}
									size='sm'
								/>
							</HStack>
						)}
					</Flex>
				) : null}
			</Box>
		</Box>
	);
}

DataTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			Header: PropTypes.string.isRequired,
			accessor: PropTypes.string.isRequired,
			isDateField: PropTypes.bool,
			filter: PropTypes.shape({
				type: PropTypes.oneOf(['date', 'select', 'async-select']),
				options: PropTypes.arrayOf(
					PropTypes.shape({
						label: PropTypes.string,
						value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
					})
				),
			}),
		})
	),
	url: PropTypes.string.isRequired,
	filter: PropTypes.bool,
	filterIsOpen: PropTypes.bool,
	expandableRows: PropTypes.bool,
	exportCSV: PropTypes.bool,
	addNewFunction: PropTypes.func,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			tooltip: PropTypes.string,
			icon: PropTypes.elementType,
			color: PropTypes.string,
			onTrigger: PropTypes.func,
		})
	),
	pageHeader: PropTypes.string,
	refreshData: PropTypes.bool,
	customButtonFunction: PropTypes.func,
	isSearchable: PropTypes.bool,
	isPagination: PropTypes.bool,
	width: PropTypes.string,
};

DataTable.defaultProps = {
	filter: false,
	filterIsOpen: false,
	expandableRows: false,
	exportCSV: false,
	addNewFunction: null,
	actions: null,
	refreshData: true,
	customButtonFunction: null,
	isSearchable: true,
	isPagination: true,
};
