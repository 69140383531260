import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export const EditCityModalForm = ({
	url,
	isOpen,
	onClose,
	selectedItem,
	refetchData,
}) => {
	const initialValues = {
		name: selectedItem ? selectedItem.name : '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('City is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.put(`${url}/${selectedItem.id}`, { ...values })
				.then((res) => {
					toast({
						description: 'Item updated successfully',
						status: 'success',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					onClose();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	return (
		<Modal
			closeOnOverlayClick={false}
			isOpen={isOpen}
			onClose={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit City</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl
							isInvalid={formik.touched.name && formik.errors.name}
							mb='4'
						>
							<FormLabel>City</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Update
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
