import { useDisclosure } from '@chakra-ui/hooks';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DataTable from '../../../components/DataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal';
import { ZMS_BASE_URL } from '../../../constants';
import { AddZoneModalForm } from './partials/AddZoneModalForm';
import { EditZoneModalForm } from './partials/EditZoneModalForm';

export default function ZoneList() {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);
	const [siteFilters, setSiteFilters] = useState(null);

	const url = useMemo(() => `${ZMS_BASE_URL}/zone`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Zone',
				accessor: 'name',
			},
			{
				Header: 'Store',
				accessor: 'site_id',
				custom: (v) => {
					let temp = _.find(siteFilters, (x) => {
						if (x.value === v) {
							return true;
						}
					});
					return temp.label;
				},
				filter: {
					type: 'select',
					options: siteFilters,
				},
				CSVFormat: (v) => {
					let temp = _.find(siteFilters, (x) => {
						if (x.value === v) {
							return true;
						}
					});
					return temp.label;
				},
			},
		],
		[siteFilters]
	);

	const toast = useToast();

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: BsPencilFill,
				color: 'green',
				onTrigger: (item) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: BsTrashFill,
				color: 'red',
				onTrigger: (item) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	useEffect(() => {
		axios
			.get(`${ZMS_BASE_URL}/sites`)
			.then((res) => {
				let temp = [];
				res.data.data.forEach((val) => {
					temp.push({
						label: val.website_name,
						value: val.id,
					});
				});
				setSiteFilters(temp);
			})
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
				}
			});
	}, [toast]);

	return (
		<>
			<EditZoneModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddZoneModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			{siteFilters && (
				<DataTable
					columns={columns}
					actions={actions}
					url={url}
					exportCSV={true}
					addNewFunction={openAddModal}
					pageHeader='Zone List'
					refetchFlag={refetchFlag}
					filter={true}
					width='4xl'
				/>
			)}
		</>
	);
}
