import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
} from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import {
	Box,
	Divider,
	Flex,
	Heading,
	LinkBox,
	LinkOverlay,
	Text,
	VStack,
} from '@chakra-ui/layout';
import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/modal';
import React from 'react';
import {
	BsCartFill,
	BsClockFill,
	BsGeoFill,
	BsLink45Deg,
	BsMapFill,
	BsPeopleFill,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../../media/svgs';

const SidenavContent = () => {
	const routes = [
		{
			name: 'User Management',
			key: 'ums',
			icon: BsPeopleFill,
			collapse: [
				{
					name: 'User list',
					key: 'ums-user-list',
					path: '/ums/user-list',
				},
			],
		},
		{
			name: 'Zone Management',
			key: 'zms',
			icon: BsMapFill,
			collapse: [
				{
					name: 'City list',
					key: 'zms-city-list',
					path: '/zms/city-list',
				},
				{
					name: 'Area list',
					key: 'zms-area-list',
					path: '/zms/area-list',
				},
				{
					name: 'Zone list',
					key: 'zms-zone-list',
					path: '/zms/zone-list',
				},
			],
		},
		{
			name: 'Timeslot Management',
			key: 'tms',
			icon: BsClockFill,
			collapse: [
				{
					name: 'Timeslot list',
					key: 'tms-timeslot-list',
					path: '/tms/timeslot-list',
				},
			],
		},
		// {
		// 	name: 'Order Management',
		// 	key: 'oms',
		// 	icon: BsCartFill,
		// 	collapse: [
		// 		{
		// 			name: 'Order list',
		// 			key: 'oms-order-list',
		// 			path: '/oms/order-list',
		// 		},
		// 	],
		// },
		{
			name: 'Site Management',
			key: 'sms',
			icon: BsGeoFill,
			collapse: [
				{
					name: 'Site list',
					key: 'sms-site-list',
					path: '/sms/site-list',
				},
			],
		},
		{
			name: 'Order Management',
			key: 'oms',
			icon: BsCartFill,
			collapse: [
				{
					name: 'Order list',
					key: 'oms-order-list',
					path: '/oms/order-list',
				},
				{
					name: 'Reports',
					key: 'oms-reports',
					path: '/oms/reports',
				},
			],
		},
	];

	const location = useLocation();

	return (
		<Box bg='#232940' height='100%'>
			<Box mb='16'>
				<LinkBox>
					<Flex alignItems='center' justifyContent='center'>
						{/* <Image
							boxSize='35px'
							src={`${process.env.PUBLIC_URL}/media/logo.png`}
							mr='2'
						/> */}
						<Logo height='2em' width='2em' mr='1' />
						<Heading size='md' py='6' textAlign='center' color='white'>
							<LinkOverlay to='/' as={Link}>
								Nesto Ecomm
							</LinkOverlay>
						</Heading>
					</Flex>
				</LinkBox>
				<Divider />
			</Box>
			<Accordion allowToggle allowMultiple>
				{routes.map((route) => {
					return (
						<AccordionItem border='none' key={route.key}>
							{({ isExpanded }) => (
								<>
									<AccordionButton
										color='white'
										bg={
											isExpanded ||
											route.key === location.pathname.split('/')[1]
												? 'linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);'
												: '#232940'
										}
										_hover={{
											bg: 'linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);',
										}}
										justifyContent='space-between'
										role='group'
										_focus={{ outline: 'none' }}
										mb='4'
									>
										<Flex textAlign='left' alignItems='center'>
											<Icon
												as={route.icon}
												_groupHover={{ color: 'blue.400' }}
												color={
													isExpanded ||
													route.key === location.pathname.split('/')[1]
														? 'blue.400'
														: 'inherit'
												}
											/>
											<Box
												as='span'
												ml='2'
												_groupHover={{ color: 'white' }}
												fontWeight={
													isExpanded ||
													route.key === location.pathname.split('/')[1]
														? '500'
														: 'normal'
												}
											>
												{route.name}
											</Box>
										</Flex>
										<AccordionIcon _groupHover={{ color: 'white' }} />
									</AccordionButton>
									<AccordionPanel p='0'>
										<VStack>
											{route.collapse.map((subRoutes) => {
												return (
													<Button
														as={Link}
														to={subRoutes.path}
														isFullWidth
														rounded='none'
														bg={
															subRoutes.path === location.pathname
																? 'linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);'
																: 'none'
														}
														fontWeight={
															subRoutes.path === location.pathname
																? '500'
																: 'normal'
														}
														color='white'
														_hover={{
															bg: 'linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);',
														}}
														role='group'
														justifyContent='start'
														pl='8'
														leftIcon={
															<Icon
																as={BsLink45Deg}
																_groupHover={{ color: 'blue.400' }}
																color={
																	subRoutes.path === location.pathname
																		? 'blue.400'
																		: 'none'
																}
															/>
														}
														key={subRoutes.key}
														_focus={{ outline: 'none' }}
														_last={{ mb: '4' }}
													>
														<Text mb='1' _groupHover={{ color: 'white' }}>
															{subRoutes.name}
														</Text>
													</Button>
												);
											})}
										</VStack>
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Box>
	);
};

export default function Sidenav({ isOpen, onClose }) {
	return (
		<>
			<Drawer
				placement='left'
				onClose={onClose}
				isOpen={isOpen}
				size='xs'
				bg='white'
				as='nav'
			>
				<DrawerOverlay />
				<DrawerContent>
					<SidenavContent />
				</DrawerContent>
			</Drawer>
			<Box
				shadow='md'
				position='fixed'
				width='280px'
				left='0'
				top='0'
				height='100vh'
				display={{ base: 'none', lg: 'block' }}
				bg='white'
				as='nav'
			>
				<SidenavContent />
			</Box>
		</>
	);
}
