import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
	components: {
		Table: {
			sizes: {
				md: {
					th: {
						px: 4,
					},
					td: {
						px: 4,
					},
				},
			},
		},
	},
});
