import { Alert, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Grid } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { AsyncSelect } from 'chakra-react-select';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ZMS_BASE_URL } from '../../../../constants';

export const EditTimeslotModalForm = ({
	url,
	isOpen,
	onClose,
	refetchData,
	selectedItem,
}) => {
	const initialValues = {
		name: selectedItem ? selectedItem.name : '',
		zone_id: selectedItem ? selectedItem.zone_id : '',
		active_from: selectedItem
			? format(parseISO(selectedItem.active_from), 'yyyy-MM-dd')
			: '',
		active_to: selectedItem
			? format(parseISO(selectedItem.active_to), 'yyyy-MM-dd')
			: '',
		customer_group: selectedItem ? selectedItem.customer_group : '',
		status: selectedItem ? selectedItem.status : '',
		sort_order: selectedItem ? selectedItem.sort_order : '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		zone_id: Yup.number().required('Zone is required'),
		active_from: Yup.string().required('Active from date is required'),
		active_to: Yup.string().required('Active to date is required'),
		customer_group: Yup.string()
			.oneOf(['general', 'other'])
			.required('Customer group is required'),
		status: Yup.number().oneOf([1, 0]).required('Status is required'),
		sort_order: Yup.number().required('Sort order is required'),
	});

	const toast = useToast();
	const [zoneKeyword, setZoneKeyword] = useState('');
	const [selectedZone, setSelectedZone] = useState(null);

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.put(`${url}`, { ...values, status: !!parseInt(values.status) })
				.then((res) => {
					toast({
						description: 'Item updated successfully',
						status: 'success',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const loadZoneOptions = () => {
		return axios
			.get(`${ZMS_BASE_URL}/zone`, {
				params: {
					pagination: { page: 1, limit: 30 },
					filter: { keyword: zoneKeyword },
				},
			})
			.then((res) => res.data.data)
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
						isClosable: true,
						variant: 'left-accent',
						position: 'bottom-left',
					});
					return [];
				}
			});
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	useEffect(() => {
		if (selectedItem) {
			setSelectedZone(selectedItem.zone);
		}
	}, [selectedItem]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit Timeslot</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<Grid templateColumns='repeat(2, 1fr)' gap={4}>
							<FormControl
								isInvalid={formik.touched.name && formik.errors.name}
								mb='4'
							>
								<FormLabel>Name</FormLabel>
								<Input {...formik.getFieldProps('name')} />
								<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={formik.touched.zone_id && formik.errors.zone_id}
								mb='4'
							>
								<FormLabel>Zone</FormLabel>
								<AsyncSelect
									cacheOptions
									defaultOptions
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
									loadOptions={loadZoneOptions}
									onInputChange={(v) => setZoneKeyword(v)}
									value={selectedZone}
									onChange={(v) => {
										setSelectedZone(v);
										formik.setFieldValue('zone_id', v.id);
									}}
								/>
								<FormErrorMessage>{formik.errors.zone_id}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.active_from && formik.errors.active_from
								}
								mb='4'
							>
								<FormLabel>Active From</FormLabel>
								<Input {...formik.getFieldProps('active_from')} type='date' />
								<FormErrorMessage>{formik.errors.active_from}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={formik.touched.active_to && formik.errors.active_to}
								mb='4'
							>
								<FormLabel>Active To</FormLabel>
								<Input {...formik.getFieldProps('active_to')} type='date' />
								<FormErrorMessage>{formik.errors.active_to}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.customer_group && formik.errors.customer_group
								}
								mb='4'
							>
								<FormLabel>Customer Group</FormLabel>
								<Select
									placeholder='Select an option'
									{...formik.getFieldProps('customer_group')}
								>
									<option value='general'>Nesto</option>
									<option value='other'>Other</option>
								</Select>
								<FormErrorMessage>
									{formik.errors.customer_group}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={formik.touched.status && formik.errors.status}
								mb='4'
							>
								<FormLabel>Status</FormLabel>
								<Select
									placeholder='Select an option'
									{...formik.getFieldProps('status')}
								>
									<option value='1'>Enabled</option>
									<option value='0'>Disabled</option>
								</Select>
								<FormErrorMessage>{formik.errors.status}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									formik.touched.sort_order && formik.errors.sort_order
								}
								mb='4'
							>
								<FormLabel>Sort Order</FormLabel>
								<Input {...formik.getFieldProps('sort_order')} type='number' />
								<FormErrorMessage>{formik.errors.sort_order}</FormErrorMessage>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
